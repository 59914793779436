import styled from '@emotion/styled';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const fontSizes = {
  1 : '16px',
  2 : '14px',
  3 : '12px',
  4 : '11px',
  5 : '10px',
  6 : '9px',
}

const ListItem = ({ location, item }) => {
  return <ListItemLink to={location.pathname + item.url} style={{
    paddingLeft : `${12*(item.level-1)}px`,
    fontSize   : `${fontSizes[item.level]}`,
  }}>{item.title}</ListItemLink>;
};

const ListItemLink = styled(Link)`
  display: inline-block;
  padding: 0.2rem 0;
  width: 100%;
  color: ${p => p.theme.colors.text};
  text-decoration: none;
  transition: color ${p => p.theme.transition};
  &:hover {
    color: ${p => p.theme.colors.primary};
    background-color: ${p => p.theme.colors.sidebar};
  }
`;

ListItem.propTypes = {
  location: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

export default ListItem;
